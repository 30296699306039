import React from "react";
import {Cloudinary} from "@cloudinary/url-gen";
import AboutPage from "./IndividualPages/AboutPage";
import SpeakerPage from "./IndividualPages/SpeakerPage"
import VideoPage from "./IndividualPages/VideoPage";
import ContactForm from "./Forms/ContactForm.js";
import Home from "./Home";
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import './App.css';

function App()  {

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.REACT_APP_CD_CN
    } 
  });

    const loadViz = cld.image('revloadsm.gif');
    const landingViz = cld.image('bokeh2.gif');
    const speakerViz = cld.image('bokeh3.gif');
    const speakMainImg = cld.image('speakblind'); 
    const vMain1 = cld.video('nwaShort'); 
    const vMain2 = cld.video('ziPath'); 
    const vMain3 = cld.video('rihShort');
    const v2Full = cld.video('zi640'); 
    const v1Full = cld.video('nwaRemFull'); 
    const v3Full = cld.video('bustaFull');
    const v4Full = cld.video('ftwfinal');
    const collabVid1 = cld.image('videoed'); 
    const collabSnd2 = cld.image('soundgif'); 
    const collabDig4 = cld.video('ziPath');
    const collabMulti3 = cld.video('ziEnd');
    const collabPort5 = cld.image('portrait-crop-2'); 
    
    const containerVariants = { 
    
      hidden: { opacity: 0 }, 
      show: {opacity: 1, 
      transition: {
          staggerChildren: 0.5}}}

    const innerVariants = {
      hidden: {opacity: 0}, show: {opacity: 1}}

    const videoVariants = {
        hidden: { opacity: 0, y: 100 },
        show: { opacity: 1, 
                y: 0, 
                transition: { 
                  duration: 1, 
                  ease: 'easeOut', 
                  delay: 0.2 }}}  
  


return (
      <div className="">
        <BrowserRouter>
      <Routes>
       <Route path="/contact" element={<ContactForm />} />
       <Route path="/multimedia" 
              element={<VideoPage vMain1={vMain1}
                                  vMain2={vMain2}
                                  vMain3={vMain3}
                                  v1Full={v1Full}
                                  v2Full={v2Full}
                                  v3Full={v3Full}
                                  v4Full={v4Full}
                                  loadViz={loadViz}
                                  vVariants={videoVariants}/>}
                                   />
       <Route path="/meet" 
              element={<AboutPage collabVid1={collabVid1}
                                  collabSnd2={collabSnd2}
                                  collabMulti3={collabMulti3}
                                  collabDig4={collabDig4}
                                  collabPort5={collabPort5}
                                  cVariants={containerVariants}
                                  iVariants={innerVariants}/>} />

       <Route path="/speaking" 
              element={<SpeakerPage speakerViz={speakerViz}/>} />

       <Route path="/" 
              element={<Home loadViz={loadViz} 
                              landingViz={landingViz}
                              speakMainImg={speakMainImg}
                              vMain1={vMain1}
                              vMain2={vMain2}
                              vMain3={vMain3}
                              cVariants={containerVariants}
                              iVariants={innerVariants}
                              vVariants={videoVariants}/>}
                              /> 
      </Routes>
      </BrowserRouter>
      </div>
   

)
}

export default App;
